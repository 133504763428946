<template>
  <div class="menu">
    <h2 class="menu-group-heading">{{ $t("menu.pizza") }} 🍕</h2>
    <div class="menu-group">
      <div v-for="pizza in pizzas" :key="pizza.id" class="menu-item">
        <div class="menu-item-text">
          <h3 class="menu-item-heading">
            <span class="menu-item-name">{{ pizza.name }}</span>
            <span class="menu-item-price">{{ pizza.price }}€</span>
          </h3>
          <p class="menu-item-desc">
            <span v-for="(ingredient, index) in translateIngredients(pizza.description)" :key="index">
              {{ ingredient }}<span v-if="index < pizza.description.length - 1">, </span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebase';
import { onSnapshot, collection } from 'firebase/firestore';

export default {
  name: 'PizzePage',
  data() {
    return {
      pizzas: [],
    };
  },
  created() {
    const pizzasCollection = collection(db, 'pizzas');
    onSnapshot(pizzasCollection, snapshot => {
      this.pizzas = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        description: doc.data().description.split(', ')
      }));
    });
  },
  methods: {
    translateIngredients(ingredients) {
      return ingredients.map(ingredient => this.$t(`ingredients.${ingredient}`));
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Bree+Serif|Poppins|Source+Sans+Pro|Montserrat:400,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Poppins", sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5em;
}

.menu {
  font-family: sans-serif;
  font-size: 14px;
  margin: 0 10px;
}

.menu-group-heading {
  margin: 0;
  padding-bottom: 1em;
  border-bottom: 2px solid #ccc;
}

.menu-group {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5em;
  padding: 1.5em 0;
}

.menu-item {
  display: flex;
}

.menu-item-img {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  object-fit: cover;
  margin-right: 1.5em;
}

.menu-item-text {
  flex-grow: 1;
}

.menu-item-heading {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.menu-item-name {
  margin-right: 1.5em;
}

.menu-item-desc {
  text-align: left;
  line-height: 1.6;
}

@media screen and (min-width: 992px) {
  .menu {
    font-size: 16px;
  }

  .menu-group {
    grid-template-columns: repeat(2, 1fr);
  }

  .menu-item-img {
    width: 125px;
    height: 125px;
  }
}
</style>