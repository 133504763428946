<template>
  <div id="app">
    <v-app>
      <v-container fluid class="w-100">
        <v-app-bar color="teal-darken-4" elevate fixed>
          <template v-slot:image>
            <v-img gradient="to top right, rgba(211, 209, 197, .8), rgba(222, 224, 219,.8)"></v-img>
          </template>
          <v-img class="logo" :src="require('@/assets/logo.png')" max-height="50" max-width="50"></v-img>
          <v-spacer />
          <v-btn text to="/">{{ $t("menu.pizza") }}</v-btn>
          <v-btn text to="/piatti">{{ $t("menu.plats") }}</v-btn>
          <v-btn text to="/bevande">{{ $t("menu.boissons") }}</v-btn>
          <v-btn text to="/vini">{{ $t("menu.vins") }}</v-btn>
          <v-btn text to="/dolci">{{ $t("menu.desserts") }}</v-btn>
          <v-spacer />
          <!-- <v-btn to="/login"><v-icon>mdi-account</v-icon></v-btn> -->

          <v-menu location="en" offset="-45">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props"><country-flag style="margin:0!important" :country='this.$i18n.locale'
                  size='normal' /></v-btn>
            </template>

            <v-list>
              <v-list-item v-for="(item, i) in returnAvailableLanguage" :key="i">
                <v-list-item-title class="languageChanger">
                  <country-flag shadow @click="changeLanguage(item.value)" :country="item.flag" size="normal" />
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
        <v-main class="w-100">
          <router-view></router-view>
          <v-footer border class="footer">
            ⚠️ {{ $t("rule1") }} <BR />
            ⚠️ {{ $t("rule2") }}<BR />
            ⚠️ {{ $t("rule3") }}
          </v-footer>
        </v-main>
      </v-container>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      items: [
        { flag: 'fr', value: 'fr' },
        { flag: 'gb', value: 'gb' },
        { flag: 'de', value: 'de' },
        { flag: 'it', value: 'it' },
        { flag: 'es', value: 'es' },
        { flag: 'pt', value: 'pt' },
        { flag: 'ru', value: 'ru' },
        { flag: 'cn', value: 'cn' },
        { flag: 'jp', value: 'jp' },
      ],
    };
  },
  computed: {
    returnAvailableLanguage() {
      return this.items.filter(item => item.value !== this.$i18n.locale);
    }
  },
  methods: {
    changeLanguage(lang) {
      this.$nextTick(() => {
        this.$i18n.locale = lang;
      });
    }
  }
};
</script>

<style>
.logo {
  margin-left: 10px;
}

nav {
  display: flex;
  justify-content: space-around;
  background-color: #f8f9fa;
  padding: 1em;
}

nav a {
  text-decoration: none;
  color: #007bff;
}

nav a:hover {
  color: #0056b3;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.languageChanger {
  cursor: pointer;
}

.footer {
  font-size: 14px !important;
  font-weight: bold !important;
  color: red !important;
  text-align: start !important;
}

@media screen and (max-width: 600px) {
  .v-img__img--contain {
    display: none !important;
  }

  .v-toolbar__content {
    overflow-x: auto;
  }

  .footer {
    font-size: 12px !important;
    font-weight: bold !important;
    color: red !important;
    text-align: start !important;
  }
}</style>
