<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" xs="12" md="6">
        <v-card class="elevation-12">
          <v-toolbar color="teal-darken-4">
          <template v-slot:image>
            <v-img gradient="to top right, rgba(211, 209, 197, .8), rgba(222, 224, 219,.8)"></v-img>
          </template>
            <v-toolbar-title>Admin</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field v-model="email" density="compact" placeholder="Identifiant" prepend-inner-icon="mdi-email-outline"
                variant="outlined"></v-text-field>
              <v-text-field v-model="password" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
                density="compact" placeholder="Mot de passe" prepend-inner-icon="mdi-lock-outline"
                variant="outlined" v-on:keyup.enter="login" @click:append-inner="visible = !visible"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green-lighten-2" @click="login">Se connecter</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { auth } from '@/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      password: '',
      visible: false,
    };
  },
  methods: {
    login() {
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          this.$router.push('/admin');
        })
        .catch(error => {
          console.error('Error logging in:', error);
        });
    }
  }
};
</script>
