<template>
  <div class="menu">
    <div class="menu-group">
      <div class="menu-item" v-for="autre in autres" :key="autre.id">
        <div class="menu-item-text">
          <h3 class="menu-item-heading">
            <h3 class="menu-item-name">{{ autre.name }}</h3>
            <span class="menu-item-price">{{ autre.price }}€</span>
          </h3>
          <p class="menu-item-desc">
            {{ $t(`${autre.description}`) }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="menu">
    <h2 class="menu-group-heading">{{ $t("carte.entrées")}} 🥗</h2>
    <div class="menu-group">
      <div v-for="entree in entrees" :key="entree.id" class="menu-item">

        <div class="menu-item-text">
          <h3 class="menu-item-heading">
            <span class="menu-item-name">{{ entree.name }}</span>
            <span class="menu-item-price">{{ entree.price }}€</span>
          </h3>
          <span v-for="(ingredient, index) in translateIngredients(entree.description)" :key="index">
              {{ ingredient }}<span v-if="index < entree.description.length - 1">, </span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="menu">
    <h2 class="menu-group-heading">{{ $t("carte.viandes")}} 🍖</h2>
    <div class="menu-group">
      <div class="menu-item" v-for="viande in viandes" :key="viande.id">
        <div class="menu-item-text">
          <h3 class="menu-item-heading">
            <span class="menu-item-name">{{ viande.name }}</span>
            <span class="menu-item-price">{{ viande.price }}€</span>
          </h3>
          <span v-for="(ingredient, index) in translateIngredients(viande.description)" :key="index">
              {{ ingredient }}<span v-if="index < viande.description.length - 1">, </span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="menu">
    <h2 class="menu-group-heading">{{ $t("carte.pates")}} 🤌</h2>
    <div class="menu-group">
      <div class="menu-item" v-for="pate in pates" :key="pate.id">
        <div class="menu-item-text">
          <h3 class="menu-item-heading">
            <span class="menu-item-name">{{ pate.name }} {{ pate.gluten ? '🔸' : '' }}</span>
            <span class="menu-item-price">{{ pate.price }}€</span>
          </h3>
          <span v-for="(ingredient, index) in translateIngredients(pate.description)" :key="index">
              {{ ingredient }}<span v-if="index < pate.description.length - 1">, </span>
          </span>
        </div>
      </div>
      <div style="text-align: justify;">🔸{{$t("Disponible sans gluten pour 1€ de plus")}}</div>
    </div>
  </div>
</template>
  
<script>
import { db } from '@/firebase';
import { onSnapshot, collection } from 'firebase/firestore';

export default {
  name: 'PiattiPage',
  data() {
    return {
      entrees: [],
      viandes: [],
      pates: [],
      autres: []
    };
  },
  methods: {
    translateIngredients(ingredients) {
      return ingredients.map(ingredient => this.$t(`ingredients.${ingredient}`));
    }
  },
  created() {
    const entreesCollection = collection(db, 'entrées');
    onSnapshot(entreesCollection, snapshot => {
      this.entrees = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        description: doc.data().description.split(', ')
      }));
    });
    const viandesCollection = collection(db, 'viandes');
    onSnapshot(viandesCollection, snapshot => {
      this.viandes = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        description: doc.data().description.split(', ')
      }));
    });
    const patesCollection = collection(db, 'pâtes');
    onSnapshot(patesCollection, snapshot => {
      this.pates = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        description: doc.data().description.split(', ')
      }));
    });
    const autresCollection = collection(db, 'autres');
    onSnapshot(autresCollection, snapshot => {
      this.autres = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    });
  }
};
</script>
  
<style>
@import url("https://fonts.googleapis.com/css?family=Bree+Serif|Poppins|Source+Sans+Pro|Montserrat:400,900&display=swap");

* {
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Poppins", sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5em;
}

.menu {
  font-family: sans-serif;
  font-size: 14px;
}

.menu-group-heading {
  margin: 0;
  padding-bottom: 1em;
  border-bottom: 2px solid #ccc;
}

.menu-group {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5em;
  padding: 1.5em 0;
}

.menu-item {
  display: flex;
}

.menu-item-img {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  object-fit: cover;
  margin-right: 1.5em;
}

.menu-item-text {
  flex-grow: 1;
  text-align: start;
}

.menu-item-heading {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.menu-item-name {
  margin-right: 1.5em;
}

.menu-item-desc {
  text-align: left;
  line-height: 1.6;
}

@media screen and (min-width: 992px) {
  .menu {
    font-size: 16px;
  }

  .menu-group {
    grid-template-columns: repeat(2, 1fr);
  }

  .menu-item-img {
    width: 125px;
    height: 125px;
  }
}
</style>