<template>
  <div>
    <h3>Ajouter ou modifier vos produits</h3>
    <!-- <v-card class="mx-auto" max-width="300">
        <v-list :items="categories"></v-list>
      </v-card> -->
    <form @submit.prevent="addMenuItem" class="mb-4 newItem">
      <select v-model="newItem.category" required>
        <option value="">Sélectionnez une catégorie</option>
        <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
      </select>
      <input v-model="newItem.name" placeholder="Nom du plat" required>
      <input v-model.number="newItem.price" placeholder="Prix" type="number" required>
      <v-btn density="compact" type="submit">Ajouter</v-btn>
    </form>
    <div v-for="category in categories" :key="category">
      <v-card class="mx-auto mb-3">
        <h2>{{ category }}</h2>
        <ul>
          <li v-for="item in menuItems[category]" :key="item.id">
            <div v-if="editedItem && editedItem.id === item.id">
              <v-row justify="center" align="center">
                <v-col>
                  <v-card-text>
                    <v-text-field v-model="editedItem.name" density="compact" label="Nom du plat" variant="solo"
                      hide-details single-line></v-text-field>
                  </v-card-text>
                </v-col>

                <v-col cols="3">
                  <v-card-text>
                    <v-text-field v-model="editedItem.price" density="compact" label="Prix" variant="solo" hide-details
                      single-line></v-text-field>
                  </v-card-text>
                </v-col>
                <!-- <input v-model.number="editedItem.price" placeholder="Prix" type="number" required> -->
                <v-col cols="4">
                  <v-btn append-icon="mdi-check-circle" @click="saveMenuItem(category, item.id)" density="compact">
                    <template v-slot:append>
                      <v-icon color="success"></v-icon>
                    </template>
                    Sauvegarder
                  </v-btn>
                  <v-btn append-icon="mdi-cancel" @click="cancelEdit" density="compact">
                    <template v-slot:append>
                      <v-icon color="error"></v-icon>
                    </template>
                    Annuler
                  </v-btn>
                </v-col>
              </v-row>


              <!-- <input v-model="editedItem.name" placeholder="Nom du plat" required> -->
            </div>
            <div v-else>
              <v-row>
                <v-col>
                  {{ item.name }} - {{ item.price }} €
                  <v-btn prepend-icon="mdi-pencil" class="ma-2" density="compact"
                    @click="editMenuItem(item)">Éditer</v-btn>
                  <v-btn rounded :icon="`mdi-delete`" color="red-darken-3" variant="outlined" density="compact"
                    @click="deleteMenuItem(category, item.id)"></v-btn>
                </v-col>
              </v-row>
            </div>
          </li>
        </ul>
      </v-card>
    </div>
  </div>
</template>

<script>
import { db, auth } from '@/firebase';
import { collection, addDoc, deleteDoc, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { reactive, ref, onMounted } from 'vue';

export default {
  name: 'AdminPage',
  setup() {
    const categories = ['autres', 'desserts', 'entrées', 'glaces', 'pizzas', 'pâtes', 'viandes'];
    const newItem = reactive({
      category: '',
      name: '',
      price: 0
    });
    const menuItems = reactive({});
    const editedItem = ref(null);

    onMounted(() => {
      auth.onAuthStateChanged(user => {
        if (!user) {
          console.log('User not authenticated');
          this.$router.push('/login');
        } else {
          console.log('User authenticated:', user.email);
          categories.forEach(category => {
            const menuCollection = collection(db, category);
            onSnapshot(menuCollection, snapshot => {
              menuItems[category] = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            }, error => {
              console.error(`Error fetching menu items for category ${category}:`, error);
            });
          });
        }
      });
    });

    const addMenuItem = () => {
      if (!newItem.name || newItem.price <= 0 || !newItem.category) {
        alert('Veuillez remplir correctement le formulaire');
        return;
      }
      addDoc(collection(db, newItem.category), {
        name: newItem.name,
        price: newItem.price
      })
        .then(() => {
          newItem.category = '';
          newItem.name = '';
          newItem.price = 0;
        })
        .catch(error => {
          console.error('Error adding menu item:', error);
          alert('Erreur lors de l\'ajout du plat');
        });
    };

    const deleteMenuItem = (category, id) => {
      deleteDoc(doc(db, category, id))
        .then(() => {
          console.log('Item deleted successfully');
        })
        .catch(error => {
          console.error('Error deleting menu item:', error);
          alert('Erreur lors de la suppression du plat');
        });
    };

    const editMenuItem = item => {
      editedItem.value = { ...item };
    };

    const saveMenuItem = (category, id) => {
      const itemRef = doc(db, category, id);
      updateDoc(itemRef, {
        name: editedItem.value.name,
        price: editedItem.value.price
      })
        .then(() => {
          editedItem.value = null;
        })
        .catch(error => {
          console.error('Error updating menu item:', error);
          alert('Erreur lors de la mise à jour du plat');
        });
    };

    const cancelEdit = () => {
      editedItem.value = null;
    };

    return {
      categories,
      newItem,
      menuItems,
      editedItem,
      addMenuItem,
      deleteMenuItem,
      editMenuItem,
      saveMenuItem,
      cancelEdit
    };
  }
};
</script>

<style>
.newItem {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  border-color: red;
  display: flex;
  justify-content: space-between;
  max-width: 80%;
  margin: auto;
}
@media (max-width: 600px) {
  .newItem {
    flex-direction: column;
  }
}
</style>